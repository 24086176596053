import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Link to="/" className="button is-text has-text-weight-bold is-capitalized is-size-5 has-text-black">Todoer</Link>
        </div>
      </div>
      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <Link to="/automation" class="button">Automation</Link>
          </div>
        </div>
        <div className="navbar-item">
          <div className="buttons">
            <AmplifySignOut />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
