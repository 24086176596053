import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { Auth } from '@aws-amplify/auth';

class CustomConfirmSignUp extends ConfirmSignUp {
  confirm = () => {
		const username = this.usernameFromAuthData() || this.inputs.username;
		const { code } = this.inputs;

		Auth.confirmSignUp(username, code)
			.then(() => this.changeState('signedUp'))
			.catch(err => this.error(err));
  };

  showComponent() {
		const username = this.usernameFromAuthData();

    return (
      <section className="hero is-primary is-fullheight">
        <div class="hero-head">
          Confirm Sign-Up
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered">
              <div className="column is-3-widescreen is-4-desktop is-5-tablet is-4">
                <div className="box">
                  <div className="field">
                    <div className="label">Email</div>
                    <p className="control has-icons-left">
                      <input
                        type="email"
                        placeholder="user@email.com"
                        className="input"
                        id="username"
                        key="username"
                        name="username"
                        onChange={this.handleInputChange}
                        value={username}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-envelope" />
                      </span>
                    </p>
                  </div>
                  <div className="field">
                    <div className="label">Confirmation Code</div>
                    <p className="control has-icons-left">
                      <input
                        autoFocus
                        type="text"
                        placeholder="code"
                        className="input"
                        id="code"
                        key="code"
                        name="code"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-lock" />
                      </span>
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      <button type="button" className="button is-text" text onClick={this.resend}>Resend Code</button>
                    </p>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className="button is-link is-success"
                      onClick={this.confirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-foot" />
      </section>
    );
  }
}

export default CustomConfirmSignUp;
