import { identify, raiseEvent } from '@x-functions/website-analytics-sdk';
import { Hub } from 'aws-amplify';
import { createAction, createReducer } from 'redux-act';
import { Auth } from '../aws';
import api from '../utils/api';

const setUserId = createAction('/user/id/set');
const setUserEmail = createAction('/user/email/set');

export const getXFToken = async () => {
  const { data } = await api.post('/xf-credentials');
  return data;
};

export const updateUserDetails = () => async (dispatch) => {
  const session = await Auth.currentSession();
  const payload = session.getIdToken().decodePayload();

  identify(payload.sub, {
    email: payload.email,
    name: payload.name,
  });
  raiseEvent('add-touchpoint', {
    userId: payload.sub,
    channelType: 'EMAIL',
    address: payload.email,
  });
  raiseEvent('add-touchpoint', {
    userId: payload.sub,
    channelType: 'SMS',
    address: payload.phone_number,
  });

  dispatch(setUserId(payload.sub));
  dispatch(setUserEmail(payload.email));
};

Hub.listen('auth', updateUserDetails);

export default createReducer({
  [setUserId]: (state, userId) => ({
    ...state,
    userId,
  }),
  [setUserEmail]: (state, email) => ({
    ...state,
    email,
  }),
}, {
  roles: {},
  currentOrg: '',
  userId: '',
  email: '',
});

export const selectUserId = state => state.user.userId;
export const selectUserEmail = state => state.user.email;
