import Amplify, { Auth, API } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true,
  },
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_graphqlEndpoint: 'https://h4wr5yyczvch3mchpcvjmixboq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

export {
  Auth,
  API,
};
