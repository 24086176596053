import { createAction, createReducer } from 'redux-act';
import { API, graphqlOperation } from 'aws-amplify';
import { getBoard } from '../graphql/queries';
import {
  createCard,
  deleteCard,
  updateCard as updateCardMutation,
} from '../graphql/mutations';

const setBoard = createAction('/board/set');
const addNewCard = createAction('/board/tasks/add');
const updateCardAction = createAction('/board/tasks/update');
const removeCardAction = createAction('/board/tasks/delete');

const emptyTodoColumn = {
  id: 'todo',
  title: 'To Do',
  cards: [],
};

const emptyInProgressColumn = {
  id: 'in progress',
  title: 'In Progress',
  cards: [],
};

const emptyDoneColumn = {
  id: 'done',
  title: 'Done',
  cards: [],
};

export const fetchBoard = () => async dispatch => {
  const { data: { getBoard: data } } = await API.graphql(graphqlOperation(getBoard));
  const columns = [
    data?.columns?.find(c => c.id === 'todo') || emptyTodoColumn,
    data?.columns?.find(c => c.id === 'in progress') || emptyInProgressColumn,
    ...data?.columns?.filter(c => c.id !== 'todo' && c.id !== 'done' && c.id !== 'in progress') || [],
    data?.columns?.find(c => c.id === 'done') || emptyDoneColumn,
  ]
  console.log({ columns });
  dispatch(setBoard({ columns }));
}

export const addCard = card => async dispatch => {
  const { data: { createCard: data } } = await API.graphql(graphqlOperation(createCard, { card }));
  dispatch(addNewCard(data));
}

export const updateCard = card => async dispatch => {
  const { data: { updateCard: data } } = await API.graphql(graphqlOperation(updateCardMutation, { card }));
  dispatch(updateCardAction(data));
}

export const removeCard = cardId => async dispatch => {
  const { data: { deleteCard: data } } = await API.graphql(graphqlOperation(deleteCard, { cardId }));
  dispatch(removeCardAction(data));
}

export default createReducer({
  [setBoard]: (state, { columns }) => {
    return { ...state, columns };
  },
  [addNewCard]: (state, card) => {
    const { columns } = state;
    const columnIndex = columns.findIndex(c => c.id === card.columnId) || columns.length;
    const column = columns[columnIndex]

    if (!column) return state;

    return {
      ...state,
      columns: [
        ...columns.slice(0, columnIndex),
        {
          ...column,
          cards: [card, ...column.cards],
        },
        columns.slice(columnIndex + 1),
      ],
    }
  },
  [removeCardAction]: (state, card) => {
    const { columns } = state;
    const columnIndex = columns.findIndex(c => c.id === card.columnId) || columns.length;
    const column = columns[columnIndex]

    if (!column) return state;

    return {
      ...state,
      columns: [
        ...columns.slice(0, columnIndex),
        {
          ...column,
          cards: column.cards.filter(c => c.id !== card.id),
        },
        columns.slice(columnIndex + 1),
      ],
    }
  },
  [updateCardAction]: (state, card) => {
    const { columns } = state;
    const columnIndex = columns.findIndex(c => c.id === card.columnId);
    const column = columns[columnIndex]

    if (!column) return state;

    const cardIndex = column.cards.findIndex(c => c.id === card.id);
    if (cardIndex < 0) return state;

    return {
      ...state,
      columns: [
        ...columns.slice(0, columnIndex),
        {
          ...column,
          cards: [
            ...column.cards.slice(0, cardIndex),
            card,
            ...columns.cards.slice(cardIndex + 1),
          ],
        },
        columns.slice(columnIndex + 1),
      ],
    }
  },
}, {
  userId: '',
  columns: [],
});

export const selectBoard = state => state.board;
