import React from 'react';
import Board from '@asseinfo/react-kanban';
import { raiseEvent } from '@x-functions/website-analytics-sdk';
import { v4 } from 'uuid';
import useBoard from '../../hooks/board';
import '@asseinfo/react-kanban/dist/styles.css';

function Home() {
  const [loading, setLoading] = React.useState(true);
  const { board, fetchBoard, addCard, removeCard, updateCard } = useBoard();

  React.useEffect(() => {
    setLoading(true);
    fetchBoard().catch(() => true).then(() => setLoading(false));
  }, [fetchBoard]);

  const handleNewCard = React.useCallback(card => {
    const newCard = { id: v4(), ...card }
    return newCard
  }, []);

  const handleCardCreated = React.useCallback(async (_, { id: columnId }, card) => {
    await addCard({ ...card, columnId });
    raiseEvent('card-created', card);
  }, [addCard]);

  const handleCardDragEnd = React.useCallback(async (_, card, source, destination) => {
    if (source.fromColumnId && destination.toColumnId && source.fromColumnId !== destination.toColumnId) {
      const updated = { ...card, columnId: destination.toColumnId }
      await updateCard(updated);
      raiseEvent('card-updated', {
        ...updated,
        fromColumnId: source.fromColumnId,
        toColumnId: destination.toColumnId,
      });
    }
  }, [updateCard]);

  const handleCardDeleted = React.useCallback(async (_, __, card) => {
    await removeCard(card.id);
    raiseEvent('card-removed', card);
  }, [removeCard]);

  return (
    <React.Fragment>
      <div className="p-5">
        <h1 className="title">Your current list</h1>
        <h2 className="subtitle">Get Stuff Done</h2>
      </div>
      <div className="is-flex is-justify-content-center is-align-items-center">
        {loading ? <p>Loading</p> : null}
        {!loading && board?.columns ? (
          <Board
            initialBoard={board}
            allowAddCard={{ on: 'top' }}
            allowRenameColumn
            allowRemoveCard
            onNewCardConfirm={handleNewCard}
            onCardRemove={handleCardDeleted}
            onCardNew={handleCardCreated}
            onCardDragEnd={handleCardDragEnd}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default Home;
