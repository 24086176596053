import React from 'react';
import classNames from 'classnames';
import { SignIn } from 'aws-amplify-react';
import { isValidEmail } from './utils';
import styles from './styles.module.scss';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      errors: {
        username: '',
        password: '',
      },
    };

    this.validate = field => {
      switch (field) {
        case 'username':
          if (!this.state.username) return { [field]: 'email is mandatory' };
          if (!isValidEmail(this.state.username)) return { [field]: 'invalid email address' };
          return { username: '' };
        case 'password':
          if (!this.state.password) return { [field]: 'password is mandatory' };
          return { password: '' };
        default:
          return {};
      }
    };

    this.handleBlur = e => {
      const { target: { name } } = e;
      this.setState(state => ({
        errors: {
          ...state.errors,
          ...this.validate(name),
        },
      }));
    };

    this.handleChange = e => {
      const { target: { name, value } } = e;
      this.setState({ [name]: value }, () => {
        if (this.state.errors[name]) this.handleBlur({ target: { name } });
      });
      this.handleInputChange(e);
    };

    this.handleForgotPasswordClick = e => {
      e.preventDefault();
      e.stopPropagation();

      super.changeState('forgotPassword');
    };

    this.handleSignUpClick = e => {
      e.preventDefault();
      e.stopPropagation();

      super.changeState('signUp');
    };

    this.handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        errors: {
          ...this.validate('username'),
          ...this.validate('password'),
        },
      }, () => {
        if (!this.isFormValid()) return;

        // Ensure Amplify is synchronised with this (it's a bit borked)
        this.handleInputChange({ target: { name: 'username', value: this.state.username } });
        this.handleInputChange({ target: { name: 'password', value: this.state.password } });

        // Now go
        this.setState({ loading: true });
        this.signIn(e).catch(() => {
          this.setState({ loading: false });
        });
      });
    };

    this.isFormValid = () => !(this.state.errors.username && this.state.errors.password);
  }

  showComponent() {
    return (
      <section className="hero is-primary is-fullheight">
        <div class="hero-head">
          Sign In
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered">
              <div className="column is-3-widescreen is-4-desktop is-5-tablet is-4">
                <form className="box" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <div className="label">Email</div>
                    <p className="control has-icons-left">
                      <input
                        type="email"
                        placeholder="user@email.com"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.username,
                        })}
                        id="username"
                        key="username"
                        name="username"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.username}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-envelope" />
                      </span>
                    </p>
                    {this.state.errors.username && <p className="help is-danger">{this.state.errors.username}</p>}
                  </div>
                  <div className="field">
                    <div className="label">Password</div>
                    <p className="control has-icons-left">
                      <input
                        type="password"
                        placeholder="password"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.password,
                        })}
                        id="password"
                        key="password"
                        name="password"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.password}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-lock" />
                      </span>
                    </p>
                    {this.state.errors.password && <p className="help is-danger">{this.state.errors.password}</p>}
                  </div>
                  <div className="field">
                    <p className="control">
                      <button type="button" className="button is-text" text onClick={this.handleForgotPasswordClick}>Forgot your password?</button>
                    </p>
                    <p className="control">
                      <button type="button" className="button is-text" text onClick={this.handleSignUpClick}>Don't have an account? Sign up</button>
                    </p>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className={classNames('button is-link is-success', {
                        'is-loading': this.state.loading,
                      })}
                      disabled={!this.isFormValid()}
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-foot" />
      </section>
    );
  }
}

export default CustomSignIn;
