import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persist = state => {
  const dehydrated = JSON.stringify(state);
  try {
    window.localStorage.setItem('xfStore', dehydrated);
  } catch {
    // ignore any errors here - localStorage might fail due to user privacy settings
  }
};

const retrieve = () => {
  try {
    const dehydrated = window.localStorage.getItem('xfStore');
    return JSON.parse(dehydrated);
  } catch {
    return undefined;
  }
};

const finalCreateStore = initialState => {
  const composeEnhancers = (process.env.NODE_ENV === 'production' ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const persistedState = retrieve();
  const store = createStore(
    rootReducer,
    { ...initialState, ...(persistedState || {}) },
    composeEnhancers(applyMiddleware(thunk)),
  );

  store.subscribe(() => {
    persist(store.getState());
  });

  return store;
};

export default finalCreateStore;
