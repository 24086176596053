import React from 'react';
import { getXFToken } from '../../reducers/user';

const embeddedUrl = process.env.REACT_APP_XF_EMBEDDED_URL;

function Automation() {
  React.useEffect(() => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent"
      ? "onmessage"
      : "message";

    const eventHandler = async function (e) {
      if (e?.data?.type === 'xf-auth') {
        const data = await getXFToken();
        console.log('response:', data);

        const iframeWin = document.getElementById('x-functions-iframe')?.contentWindow
        if (iframeWin) {
          iframeWin.postMessage({
            type: 'xf-auth-response',
            payload: data,
          }, e.origin);
        }
      }
    }

    eventer(messageEvent, eventHandler);

    return () => {
      if (window.removeEventListener) window.removeEventListener(messageEvent, eventHandler);
    };
  }, []);

  return (
    <div className="has-background-grey-lighter is-flex-grow-1 is-relative">
      <iframe
        id="x-functions-iframe"
        title="automation"
        src={embeddedUrl}
        style={{ width: '100%', height: '100%', position: 'absolute' }}
      />
    </div>
  );
}

export default Automation;
