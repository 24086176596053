/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createColumn = /* GraphQL */ `
  mutation CreateColumn($column: ColumnInput!) {
    createColumn(column: $column) {
      id
      title
    }
  }
`;
export const updateColumn = /* GraphQL */ `
  mutation UpdateColumn($column: UpdateColumnInput!) {
    updateColumn(column: $column) {
      id
      title
    }
  }
`;
export const deleteColumn = /* GraphQL */ `
  mutation DeleteColumn($columnId: String!) {
    deleteColumn(columnId: $columnId) {
      id
      title
    }
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard($card: CardInput!) {
    createCard(card: $card) {
      id
      columnId
      title
      description
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard($card: UpdateCardInput!) {
    updateCard(card: $card) {
      id
      columnId
      title
      description
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard($cardId: String!) {
    deleteCard(cardId: $cardId) {
      id
      columnId
      title
      description
    }
  }
`;
