import axios from 'axios';
import { Auth } from '../aws';

const baseUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '');

const api = axios.create();
api.defaults.baseURL = baseUrl;
api.interceptors.request.use(async config => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${idToken.getJwtToken()}`,
  };

  return config;
});

export default api;
