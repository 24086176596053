/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getColumnById = /* GraphQL */ `
  query GetColumnById($columnId: String!) {
    getColumnById(columnId: $columnId) {
      id
      title
    }
  }
`;
export const listColumns = /* GraphQL */ `
  query ListColumns {
    listColumns {
      id
      title
    }
  }
`;
export const getCardById = /* GraphQL */ `
  query GetCardById($cardId: String!) {
    getCardById(cardId: $cardId) {
      id
      columnId
      title
      description
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards {
    listCards {
      id
      columnId
      title
      description
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard {
    getBoard {
      columns {
        id
        title
        cards {
          id
          columnId
          title
          description
        }
      }
    }
  }
`;
