import React from 'react';
import classNames from 'classnames';
import { SignUp } from 'aws-amplify-react';
import { isValidEmail, isValidMobile } from './utils';
import styles from './styles.module.scss';

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);

    this.signUpFields = [{
      key: 'name', 
    }, {
      key: 'username',
    }, {
      key: 'password',
    }, {
      key: 'phone_number'
    }];

    this.state = {
      name: '',
      username: '',
      password: '',
      phone_number: '',
      errors: {
        name: '',
        username: '',
        password: '',
        phone_number: '',
      },
    };

    this.validate = field => {
      switch (field) {
        case 'username':
          if (!this.state.username) return { [field]: 'email is mandatory' };
          if (!isValidEmail(this.state.username)) return { [field]: 'invalid email address' };
          return { username: '' };
        case 'password':
          if (!this.state.password) return { [field]: 'password is mandatory' };
          return { password: '' };
        case 'name':
          if (!this.state.name) return { [field]: 'name is mandatory' };
          return { name: '' };
        case 'phone_number':
          if (!this.state.phone_number) return { [field]: 'mobile is mandatory' };
          if (!isValidMobile(this.state.phone_number)) return { [field]: 'invalid mobile number. Use format +610400000000' };
          return { phone_number: '' };
        default:
          return {};
      }
    };

    this.handleBlur = e => {
      const { target: { name } } = e;
      this.setState(state => ({
        errors: {
          ...state.errors,
          ...this.validate(name),
        },
      }));
    };

    this.handleChange = e => {
      const { target: { name, value } } = e;
      this.setState({ [name]: value }, () => {
        if (this.state.errors[name]) this.handleBlur({ target: { name } });
      });
      this.handleInputChange(e);
    };

    this.handleSignInClick = e => {
      e.preventDefault();
      e.stopPropagation();

      super.changeState('signIn');
    };

    this.handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        errors: {
          ...this.validate('username'),
          ...this.validate('password'),
          ...this.validate('name'),
          ...this.validate('phone_number'),
        },
      }, () => {
        if (!this.isFormValid()) return;

        // Ensure Amplify is synchronised with this (it's a bit borked)
        this.handleInputChange({ target: { name: 'username', value: this.state.username } });
        this.handleInputChange({ target: { name: 'password', value: this.state.password } });
        this.handleInputChange({ target: { name: 'name', value: this.state.name } });
        this.handleInputChange({ target: { name: 'phone_number', value: this.state.phone_number } });

        // Now go
        this.setState({ loading: true });
        this.signUp(e).catch((e) => {
          console.log(e);
          alert(e);
          this.setState({ loading: false });
        });
      });
    };

    this.isFormValid = () => !(
      this.state.errors.username &&
      this.state.errors.password &&
      this.state.errors.name &&
      this.state.errors.phone_number
    );
  }

  showComponent() {
    return (
      <section className="hero is-primary is-fullheight">
        <div class="hero-head">
          Sign Up
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered">
              <div className="column is-3-widescreen is-4-desktop is-5-tablet is-4">
                <form className="box" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <div className="label">Name</div>
                    <p className="control has-icons-left">
                      <input
                        type="name"
                        placeholder="Jane Smith"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.name,
                        })}
                        id="name"
                        key="name"
                        name="name"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.name}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-user" />
                      </span>
                    </p>
                    {this.state.errors.name && <p className="help is-danger">{this.state.errors.name}</p>}
                  </div>
                  <div className="field">
                    <div className="label">Email</div>
                    <p className="control has-icons-left">
                      <input
                        type="email"
                        placeholder="user@email.com"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.username,
                        })}
                        id="username"
                        key="username"
                        name="username"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.username}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-envelope" />
                      </span>
                    </p>
                    {this.state.errors.username && <p className="help is-danger">{this.state.errors.username}</p>}
                  </div>
                  <div className="field">
                    <div className="label">Password</div>
                    <p className="control has-icons-left">
                      <input
                        type="password"
                        placeholder="password"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.password,
                        })}
                        id="password"
                        key="password"
                        name="password"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.password}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-lock" />
                      </span>
                    </p>
                    {this.state.errors.password && <p className="help is-danger">{this.state.errors.password}</p>}
                  </div>
                  <div className="field">
                    <div className="label">Mobile</div>
                    <p className="control has-icons-left">
                      <input
                        type="phone_number"
                        placeholder="+610400000000"
                        className={classNames('input', styles.input, {
                          'is-danger': !!this.state.errors.phone_number,
                        })}
                        id="phone_number"
                        key="phone_number"
                        name="phone_number"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.phone_number}
                      />
                      <span className="icon is-small is-left">
                        <span className="fa fa-comment" />
                      </span>
                    </p>
                    {this.state.errors.phone_number && <p className="help is-danger">{this.state.errors.phone_number}</p>}
                  </div>
                  <div className="field">
                    <p className="control">
                      <button type="button" className="button is-text" text onClick={this.handleSignInClick}>Already have an account? Sign in</button>
                    </p>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className={classNames('button is-link is-success', {
                        loading: 'is-loading'
                      })}
                      disabled={!this.isFormValid()}
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-foot" />
      </section>
    );
  }
}

export default CustomSignUp;
