import { useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  fetchBoard as fetchBoardAction,
  addCard as addCardAction,
  removeCard as removeCardAction,
  updateCard as updateCardAction,
  selectBoard,
} from '../reducers/board';

function useCart() {
  const dispatch = useDispatch();
  const fetchBoard = useCallback(() => dispatch(fetchBoardAction()), [dispatch]);
  const addCard = useCallback(card => dispatch(addCardAction(card)), [dispatch]);
  const removeCard = useCallback(cardId => dispatch(removeCardAction(cardId)), [dispatch]);
  const updateCard = useCallback(cardId => dispatch(updateCardAction(cardId)), [dispatch]);
  const board = useSelector(selectBoard, shallowEqual);

  return {
    board,
    fetchBoard,
    addCard,
    removeCard,
    updateCard,
  };
}

export default useCart;
